export const drives = [
  {
    name: 'learning',
    description: 'Expanding knowledge and growing professionally.',
    value: 0
  },
  {
    name: 'team',
    description: 'Building relationships and collaborating with others.',
    value: 0
  },
  {
    name: 'money',
    description: 'Obtaining financial compensation.',
    value: 0
  },
  {
    name: 'innovation',
    description: 'Creating and inventing new ideas or products.',
    value: 0
  },
  {
    name: 'rewards',
    description: 'Earning perks for exceptional performance.',
    value: 0
  },
  {
    name: 'purpose',
    description: 'Contributing in a meaningful way.',
    value: 0
  },
  {
    name: 'recognition',
    description: 'Receiving credit and feeling valued.',
    value: 0
  },
  {
    name: 'responsibility',
    description: 'Having ownership over work activities.',
    value: 0
  },
  {
    name: 'security',
    description: 'Maintaining consistent and stable employment.',
    value: 0
  }
]
